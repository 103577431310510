import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FaCcVisa, FaCcMastercard, FaCcAmex, FaCcDinersClub, FaCcDiscover } from 'react-icons/fa';
import { SiPix } from 'react-icons/si'; // Ícone para Pix
import './Produto.css';

function Produto({ product, onAddToCart }) {
  const { productName } = useParams();
  const [mainImage, setMainImage] = useState(product?.imageUrls[0]);
  const [selectedSize, setSelectedSize] = useState('');

  useEffect(() => {
    // Scrolla para o topo da página quando o componente é montado
    window.scrollTo(0, 0);
  }, []);

  if (!product) {
    return <div>Produto não encontrado.</div>;
  }

  const handleThumbnailClick = (url) => {
    setMainImage(url);
  };

  const produto = product;
  const tamanhosDisponiveis = Array.isArray(produto.sizes) ? produto.sizes : [];
  const estoque = typeof produto.stock === 'object' ? produto.stock : {};

  const handleAddToCart = () => {
    if (!selectedSize || estoque[selectedSize] <= 0) {
      alert('Por favor, selecione um tamanho disponível.');
      return;
    }
    onAddToCart({ ...produto, selectedSize });
    
    // Scrolla para o topo da página ao adicionar um produto ao carrinho
    window.scrollTo(0, 0);
  };

  return (
    <div className="produto-container">
      <div className="produto-detalhes">
        <div className="produto-imagens-adicionais">
          {produto.imageUrls.map((url, idx) => (
            <img
              key={idx}
              src={url}
              alt={produto.name}
              className={`produto-imagem-pequena ${mainImage === url ? 'active' : ''}`}
              onClick={() => handleThumbnailClick(url)}
            />
          ))}
        </div>

        <div className="produto-imagem-e-descricao">
          <div className="produto-imagem-maior">
            <img src={mainImage} alt={produto.name} className="produto-imagem" />
          </div>
          <div className="produto-descricao">{produto.description}</div>
        </div>

        <div className="produto-info">
          <h1>{produto.name}</h1>
          <p className="preco">{`R$ ${produto.price.toFixed(2).replace('.', ',')}`}</p>
          <div className="tamanhos-disponiveis">
            <h3>Planos Disponíveis:</h3>
            <div className="tamanhos">
              {tamanhosDisponiveis.map((size) => (
                <span
                  key={size}
                  className={`tamanho ${selectedSize === size ? 'selected' : ''} ${
                    estoque[size] <= 0 ? 'indisponivel' : ''
                  }`}
                  onClick={() => estoque[size] > 0 && setSelectedSize(size)}
                >
                  {size}
                </span>
              ))}
            </div>
          </div>
          <button
            className="add-to-cart-btn"
            onClick={handleAddToCart}
            disabled={!selectedSize || estoque[selectedSize] <= 0}
          >
            Adicionar ao Carrinho
          </button>
          <div className="formas-pagamento">
            <p>Formas de pagamento:</p>
            <div className="icones-pagamento">
              <FaCcVisa className="icone-pagamento" />
              <FaCcMastercard className="icone-pagamento" />
              <FaCcAmex className="icone-pagamento" />
              <FaCcDinersClub className="icone-pagamento" />
              <FaCcDiscover className="icone-pagamento" />
              <SiPix className="icone-pagamento" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Produto;
