// src/Rodape.js
import React from 'react';
import './Rodape.css';
import { FaCcVisa, FaCcMastercard, FaCcAmex, FaCcDiscover } from 'react-icons/fa';
import { MdLocalShipping } from 'react-icons/md';
import logo from './IMG/logo.JPG';

const Rodape = () => {
  return (
    <footer className="rodape">
      <div className="rodape-info">
      <img src={logo} alt="Logo" className="rodape-logo" />
       
        <p>Saúde e Esporte</p>
      </div>
      <div className="rodape-departamentos">
        <h3>Departamentos</h3>
        <ul>
          <li><a href="/inicio">Início</a></li>
          <li><a href="/produtos">Produtos</a></li>
          <li><a href="/contato">Contato</a></li>
          <li><a href="/politica-de-privacidade">Política de Privacidade</a></li>
        </ul>
      </div>
      <div className="rodape-contato">
        <h3>Entre em contato</h3>
        <p>Telefone: (38)999211-1595</p>
        <p>Email: yourworkout@gmail.com</p>
      </div>
      <div className="rodape-pagamento-envio">
        <h3>Meios de pagamento</h3>
        <div className="bandeiras-pagamento">
          <FaCcVisa />
          <FaCcMastercard />
          <FaCcAmex />
          <FaCcDiscover />
        </div>
        <h3>Meios de envio</h3>
        <div className="bandeiras-envio">
          <MdLocalShipping />
        </div>
      </div>
    </footer>
  );
};

export default Rodape;
