import React, { useState, useEffect, useRef } from 'react';
import './Carrinho.css';
import { FaTimes } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Carrinho = ({ items, onClose }) => {
  const [cartItems, setCartItems] = useState(items);
  const carrinhoRef = useRef(null);

  useEffect(() => {
    setCartItems(items.filter(item => item.quantity > 0)); // Filtrar itens com quantidade maior que zero
  }, [items]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (carrinhoRef.current && !carrinhoRef.current.contains(event.target)) {
        onClose(); // Fecha o carrinho ao clicar fora
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [onClose]);

  const handleQuantityChange = (index, delta) => {
    const updatedItems = [...cartItems];
    updatedItems[index].quantity += delta;
    if (updatedItems[index].quantity <= 0) {
      updatedItems.splice(index, 1);
    }
    setCartItems(updatedItems);
  };

  const getTotalPrice = () => {
    return cartItems.reduce((total, item) => total + item.price * item.quantity, 0);
  };

  return (
    <div className="carrinho" ref={carrinhoRef}>
      <div className="carrinho-header">
        <h2>Carrinho</h2>
        <button className="close-btn" onClick={onClose}><FaTimes /></button>
      </div>
      <div className="carrinho-content">
        {cartItems.length === 0 ? (
          <p>Seu carrinho está vazio</p>
        ) : (
          cartItems.map((item, index) => (
            <div key={index} className="carrinho-item">
              <img src={item.imageUrls[0]} alt={item.name} className="carrinho-item-image" />
              <div className="carrinho-item-info">
                <h3>{item.name}</h3>
                <p>Tamanho: {item.selectedSize}</p>
                <p>{formatPrice(item.price)}</p>
                <div className="quantity-controls">
                  <button onClick={() => handleQuantityChange(index, -1)}>-</button>
                  <span>{item.quantity}</span>
                  <button onClick={() => handleQuantityChange(index, 1)}>+</button>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
      <div className="carrinho-footer">
        <p className="total">Total: <strong>{formatPrice(getTotalPrice())}</strong></p>
        <Link to="/checkout" className="checkout-btn" onClick={onClose}>Finalizar Compra</Link>
      </div>
    </div>
  );
};

const formatPrice = (price) => {
  if (typeof price === 'number') {
    return `R$ ${price.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`;
  }
  return price || 'R$ 0,00'; // Adicionei uma verificação para garantir que retorne um valor padrão
};

export default Carrinho;
