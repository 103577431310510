// src/firebase.js

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyA6ATFqyxXnCrC-Rr2r7YzELm0EHuFYaqo",
  authDomain: "yourworkout-1c697.firebaseapp.com",
  projectId: "yourworkout-1c697",
  storageBucket: "yourworkout-1c697.appspot.com",
  messagingSenderId: "899841354854",
  appId: "1:899841354854:web:e0de160a11723eca1051a7",
  measurementId: "G-RFM6Y7QPJG"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);

export { db, storage };
